<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-body>
            <b-form-group
              label="İş Emri Ara"
              label-for="search"
            >
              <b-input-group>
                <b-form-input
                  id="search"
                  v-model="search"
                  placeholder="Müşteri Adı, Araç Plaka, Şase No, İş Emri No..."
                  @keydown.enter="searchData"
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-50"
                    :disabled="dataLoading"
                    @click="searchData"
                  >
                    <feather-icon
                      v-if="!dataLoading"
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    <b-spinner
                      v-if="dataLoading"
                      class="mr-50"
                      small
                    />
                    Ara
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-card-body>
          <template v-if="dataLoading">
            <loading />
          </template>
          <template v-else>
            <b-table
              v-if="dataList.length > 0"
              striped
              hover
              responsive="sm"
              :fields="fields"
              :items="dataList"
            >
              <template #cell(customer)="data">
                <div>
                  <b-badge variant="light-warning">
                    <feather-icon icon="FileTextIcon" /> {{ data.item.Is_Emri_No }}
                  </b-badge>
                </div>
                <template v-if="data.item.id_com_customer">
                  <!--                <div>{{ data.item.customer }}</div>-->
                  <div>{{ data.item.Musteri_Adi }}</div>
                  <div class="text-primary">
                    <small>{{ data.item.company_name }}</small>
                  </div>
                </template>
                <template v-else>
                  <div>{{ data.item.Musteri_Adi }}</div>
                </template>
              </template>
              <template #cell(car)="data">
                <b-badge
                  v-if="!data.item.car"
                  variant="light-danger"
                >
                  <feather-icon icon="XIcon" />
                  {{ data.item.Plaka }} 'a Ait Araç Kartı Bulunamadı
                </b-badge>
                <div v-else>
                  {{ data.item.car }}
                </div>
                <div
                  v-if="data.item.car"
                  class="text-warning font-small-2"
                >
                  {{ data.item.license_plate }}
                </div>
              </template>
              <template #cell(control)="data">
                <b-button
                  :to="getAddURL(data.item)"
                  variant="outline-primary"
                  block
                  size="sm"
                  :disabled="!data.item.status"
                >
                  <template v-if="data.item.status">
                    Kayıt Oluştur
                  </template>
                  <template v-else>
                    Müşteri Kartı bulunamadı
                  </template>
                </b-button>
              </template>
            </b-table>
            <b-alert
              v-else
              show
              variant="info"
              class="m-2"
            >
              <div class="alert-body text-center">
                Sonuç bulunamadı.
              </div>
            </b-alert>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BTable,
  BCardBody,
  VBToggle,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'WorkOrderSearch',
  components: {
    Loading,
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BTable,
    BCardBody,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search: null,
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
        },
        {
          key: 'car',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'control',
          label: 'Kontrol',
          tdClass: 'text-right width-100 text-nowrap',
        },
      ],
      dataQuery: {
        order_by: ['id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['sparepartOrders/getWorkOrderSearchList']
    },
    dataLoading() {
      return this.$store.getters['sparepartOrders/dataLoading']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getAddURL(data) {
      let url = '/sparepart-orders/add-to-work-orders'
      const urlParams = []
      if (data.id_com_customer) {
        urlParams.push(`id_com_customer=${data.id_com_customer}`)
      }
      if (data.Is_Emri_No) {
        urlParams.push(`Is_Emri_No=${data.Is_Emri_No}`)
      }
      if (data.id_com_cars) {
        urlParams.push(`id_com_cars=${data.id_com_cars}`)
      }
      const params = urlParams.join('&')
      url = `${url}?${params}`
      return url
    },
    searchData() {
      this.dataQuery.or_like = {
        'tbl_IsEmirleri.Musteri_Adi': this.search,
        'tbl_IsEmirleri.Is_Emri_No': this.search,
        'tbl_IsEmirleri.Telefon': this.search,
        'tbl_IsEmirleri.Plaka': this.search,
        'tbl_IsEmirleri.Sase_No': this.search,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('sparepartOrders/workOrderSearchList', this.dataQuery)
    },
  },
}
</script>
